import { ReactNode, createContext, useReducer } from "react";
import { Conversation, FollowUpAsk, MessageAgentChatBot, NewAsk, ResponseAssistantStream, ResponseChatDetails, ResponseChatList, ResponseChatStream, ResponseExpertModeStream, SourceConnector } from "../types/types";
import { getTimestamp, saveIdentifier, timeDifference } from "../utils/utils";
import { STREAM_RESPONSE_TIMEOUT_MS } from "../constants";
import { ChatMessage, ChatSummary, SystemMessage, UserMessage } from "../types/types";
import { mergeIncomingStreamWithSystemMessage } from "@/utils/components";

type ConversationId = string;
type RequestId = string;
type MessageId = ConversationId | RequestId;
export type SourceDocumentId = string;
export type SelectedCitations = Record<MessageId, SourceDocumentId[]>

export type AssistantState = {
    selectedCitations: SelectedCitations;
    hoverCitations?: SelectedCitations;
}

export type AssistantStore = {
    newAsk: NewAsk[],
    liveConversations: {
        requestIds?: string[],
        conversationId?: string,
        conversation: Conversation;
    }[],
    list: ChatSummary[],
    state: AssistantState;
}

export enum ASSISTANT_ACTIONS {
    NEW_ASK = "new_ask",
    STREAMING_RESPONSE = "streaming_response",
    FOLLOW_UP_ASK = "follow_up_ask",
    FETCH_CONVERSATION = "fetch_conversation",
    FETCH_THREADS = "fetch_threads",
    SELECT_CITATION = "select_citation",
    UNSELECT_CITATION = "unselect_citation",
    SHOW_ALL_CITATIONS = "show_all_citations",
}

export type NewAskPayload = {
    action: ASSISTANT_ACTIONS.NEW_ASK,
    data: NewAsk,
}

export type FollowUpAskPayload = {
    action: ASSISTANT_ACTIONS.FOLLOW_UP_ASK,
    data: FollowUpAsk,
}

type DataStreamedResponse = ResponseAssistantStream & { requestId: string, timestamp: number };
export type StreamedResponsePayload = {
    action: ASSISTANT_ACTIONS.STREAMING_RESPONSE,
    data: DataStreamedResponse,
}

export type FetchConversationPayload = {
    action: ASSISTANT_ACTIONS.FETCH_CONVERSATION,
    data: ResponseChatDetails
}

export type FetchThreadsPayload = {
    action: ASSISTANT_ACTIONS.FETCH_THREADS,
    data: ResponseChatList[]
}

export type SelectCitationPayload = {
    action: ASSISTANT_ACTIONS.SELECT_CITATION,
    data: { messageId: MessageId, documentId: SourceDocumentId[] };
}

export type UnselectCitationPayload = {
    action: ASSISTANT_ACTIONS.UNSELECT_CITATION,
    data: { messageId: MessageId, documentId: SourceDocumentId[] };
}

export type ShowAllCitationsPayload = {
    action: ASSISTANT_ACTIONS.SHOW_ALL_CITATIONS,
    data: { messageId: MessageId };
}

export type ReducerPayload = NewAskPayload
    | FollowUpAskPayload
    | StreamedResponsePayload
    | FetchConversationPayload
    | FetchThreadsPayload
    | SelectCitationPayload
    | UnselectCitationPayload
    | ShowAllCitationsPayload;

export function mapperChatList(data: ResponseChatList): ChatSummary {
    return {
        conversationId: data.id,
        query: data.title,
        created_at: getTimestamp(data.created_at),
        updated_at: getTimestamp(data.updated_at)
    }
}

function checkSystemMessageFinished(s: MessageAgentChatBot) {
    if (s.is_active) return true;
    if (s.citations.length > 0) return true; // fixme
    if (timeDifference(new Date(s.updated_at), new Date()) > STREAM_RESPONSE_TIMEOUT_MS) return true;
    return false;
}

export function mapperConversation(data: ResponseChatDetails): ChatMessage[] {
    const conversationId = data.id;
    return data.messages
        .reduce((acc, cur) => {
            const d = cur;
            if (d.agent === "USER") {
                const userMessage: UserMessage = {
                    query: d.text,
                    conversationId: d.id,
                    timestamp: getTimestamp(d.created_at),
                    role: 'user'
                }
                return [...acc, userMessage];
            }
            if (d.agent === "CHATBOT") {
                let systemMessage: SystemMessage = {
                    conversationId,
                    timestamp: getTimestamp(d.created_at),
                    role: "system",
                    data: {
                        citations: d.citations,
                        documents: d.documents.map(d => {
                            return {
                                ...d,
                                document_link: d.document_link || "", // todo: to be implemented by assistant service
                            }
                        }),
                        text: d.text,
                        isFinished: checkSystemMessageFinished(d),
                        followUpQuestions: data.follow_up_questions
                    }
                }
                const plan = (data.plans || []).find(p => p.conversation_id === conversationId);
                if (plan) {
                    systemMessage.data.plan = plan;
                }
                return [...acc, systemMessage];
            }
            return acc;
        }, [] as ChatMessage[])
}

export function getLiveConversationById({
    requestId,
    conversationId,
    store
}: { requestId: string, conversationId: string, store: AssistantStore }): Conversation {
    const conversation = store.liveConversations?.find(lc =>
        (lc?.conversationId === conversationId) ||
        (lc?.requestIds?.includes(requestId))
    )
    return conversation?.conversation || [];
}

function streamingReducer(prev: AssistantStore, payload: StreamedResponsePayload): AssistantStore {
    switch (payload.data.event_type) {
        case 'stream-start': {
            saveIdentifier(
                payload.data.requestId,
                payload.data.conversation_id,
                payload.data.timestamp
            );
            return {
                ...prev,
                list: prev.list.map(li => {
                    if (li.requestId === payload.data.requestId) {
                        return {
                            ...li,
                            conversationId: payload.data.conversation_id
                        }
                    }
                    return li;
                }),
                liveConversations: prev.liveConversations?.map(lc => {
                    if (lc.requestIds?.includes(payload.data.requestId)) {
                        return {
                            ...lc,
                            conversationId: payload.data.conversation_id,
                            conversation: lc.conversation.map(c => {
                                if (c.requestId === payload.data.requestId) {
                                    return {
                                        ...c,
                                        conversationId: payload.data.conversation_id
                                    }
                                }
                                return c;
                            })
                        }
                    }
                    return lc;
                })
            }
        }

        case 'agent-response': {
            // todo: test
            return {
                ...prev,
                liveConversations: prev.liveConversations?.map(lc => {
                    if (lc.conversationId === payload.data.conversation_id || lc.requestIds?.includes(payload.data.requestId)) {
                        return {
                            ...lc,
                            conversationId: payload.data.conversation_id,
                            conversation: [
                                ...lc.conversation.map(c => {
                                    if (c.role === 'system' && !c.data.isFinished) {
                                        // update streaming response
                                        const merged = mergeIncomingExpertStreamWithSystemMessage(c, payload.data as ResponseExpertModeStream)
                                        return merged;
                                    }
                                    return c;
                                }),
                            ]
                        }
                    }
                    return lc;
                })
            }
        }

        default: {
            return {
                ...prev,
                liveConversations: prev.liveConversations?.map(lc => {
                    if (lc.conversationId === payload.data.conversation_id || lc.requestIds?.includes(payload.data.requestId)) {
                        return {
                            ...lc,
                            conversation: [
                                ...lc.conversation.map(c => {
                                    if (c.role === 'system' && !c.data.isFinished) {
                                        // update streaming response
                                        const merged = mergeIncomingStreamWithSystemMessage(c, payload.data as ResponseChatStream)
                                        return merged;
                                    }
                                    return c;
                                }),
                            ]
                        }
                    }
                    return lc;
                })
            }
        }
    }
}

export function assistantReducer(prev: AssistantStore, payload: ReducerPayload): AssistantStore {
    switch (payload.action) {
        case ASSISTANT_ACTIONS.NEW_ASK:
            return {
                ...prev,
                list: [...prev.list, {
                    requestId: payload.data.requestId,
                    query: payload.data.question,
                    created_at: payload.data.timestamp,
                    updated_at: payload.data.timestamp,
                    conversationId: ""
                }],
                newAsk: [...prev.newAsk, payload.data],
                liveConversations: [
                    ...(prev.liveConversations || []),
                    {
                        requestIds: [payload.data.requestId],
                        conversation: [
                            {
                                conversationId: "",
                                requestId: payload.data.requestId,
                                query: payload.data.question,
                                timestamp: payload.data.timestamp,
                                mode: payload.data.mode,
                                role: "user",
                            },
                            {
                                conversationId: "",
                                requestId: payload.data.requestId,
                                timestamp: payload.data.timestamp,
                                data: {
                                    ...(payload.data.mode === 'expert' && {
                                        plan: {
                                            id: "",
                                            conversation_id: "",
                                            steps: []
                                        }
                                    }),
                                    isFinished: false,
                                },
                                role: 'system',
                            }
                        ]
                    },
                ]
            }

        case ASSISTANT_ACTIONS.FOLLOW_UP_ASK:
            return {
                ...prev,
                liveConversations: prev.liveConversations?.map(lc => {
                    if ((lc.conversationId === payload.data.conversationId) || (lc.requestIds?.includes(payload.data.requestId))) {
                        return {
                            ...lc,
                            requestIds: [...(lc.requestIds || []), payload.data.requestId],
                            conversation: [
                                ...lc.conversation,
                                {
                                    query: payload.data.question,
                                    conversationId: payload.data.conversationId,
                                    role: "user",
                                    timestamp: payload.data.timestamp,
                                },
                                {
                                    conversationId: payload.data.conversationId,
                                    requestId: payload.data.requestId,
                                    timestamp: payload.data.timestamp,
                                    role: 'system',
                                    data: {
                                        ...(payload.data.mode === 'expert' && {
                                            plan: {
                                                id: "",
                                                conversation_id: "",
                                                steps: []
                                            }
                                        }),
                                        isFinished: false,
                                    }
                                }
                            ]
                        }
                    }
                    return lc;
                })
            }

        case ASSISTANT_ACTIONS.STREAMING_RESPONSE:
            return streamingReducer(prev, payload);

        case ASSISTANT_ACTIONS.FETCH_CONVERSATION:
            // replace existing conversation if it exists
            const conversationId = payload.data.id;
            if (!conversationId) {
                console.error(`[desia-web-app]: conversationId not found following ${ASSISTANT_ACTIONS.FETCH_CONVERSATION}`);
                return prev;
            }
            return {
                ...prev,
                liveConversations: [
                    ...(prev.liveConversations?.filter(lc => lc.conversationId === payload.data.id) || []),
                    {
                        requestIds: [],
                        conversationId: payload.data.id,
                        conversation: mapperConversation(payload.data).sort((a, b) => a.timestamp - b.timestamp)
                    }
                ]
            }

        case ASSISTANT_ACTIONS.FETCH_THREADS:
            return {
                ...prev,
                list: (payload?.data || []).map(mapperChatList)
            }

        case ASSISTANT_ACTIONS.SELECT_CITATION:
            return {
                ...prev,
                state: {
                    ...prev.state,
                    selectedCitations: {
                        ...prev.state.selectedCitations,
                        [payload.data.messageId]: payload.data.documentId
                    }
                }
            }

        case ASSISTANT_ACTIONS.UNSELECT_CITATION:
            return {
                ...prev,
                state: {
                    ...prev.state,
                    selectedCitations: {
                        ...prev.state.selectedCitations,
                        [payload.data.messageId]: (prev.state.selectedCitations[payload.data.messageId] || []).filter(s => !payload.data.documentId.includes(s))
                    }
                }
            }

        case ASSISTANT_ACTIONS.SHOW_ALL_CITATIONS:
            return {
                ...prev,
                state: {
                    selectedCitations: {
                        ...prev.state.selectedCitations,
                        [payload.data.messageId]: [],
                    }
                }
            }

        default:
            return prev;
    }
}

function mergeIncomingExpertStreamWithSystemMessage(prev: SystemMessage, incoming: ResponseExpertModeStream): SystemMessage {
    const next: SystemMessage = {
        ...prev,
        data: {
            ...prev.data,
            ...incoming.body,
            planDocuments: incoming.body.documents || {},
            documents: [], // fixme
        }
    }
    return next;
}

export type StreamStatus = "Ready" | "Searching" | "Searching web" | "Searching internal docs" | "Searching web and internal docs" | "Applying citations" | "Answering" | "...";
export function getStreamStatus(m: ChatMessage | null, s: SourceConnector[]): StreamStatus {
    if (!m) {
        return "Ready";
    }
    if (!m) return "..."; // todo: implement for expert mode
    if (m.role !== 'system') {
        if (s.find((v) => v.id === "internal-search") && s.find((v) => v.id === "web-search")) {
            return "Searching web and internal docs"
        }
        if (s.find((v) => v.id === "internal-search")) {
            return "Searching internal docs"
        }
        if (s.find((v) => v.id === "web-search")) {
            return "Searching web"
        }
        return "Searching"
    }
    if (m.data.isFinished) {
        return "Ready"
    }
    if (m.data.citations) {
        return "Applying citations";
    }
    if (m.data.text) {
        return "Answering";
    }
    if (m.data.documents) {
        return "Answering";
    }
    return "...";
}

export function checkAskExpired(lastMessage: (UserMessage | SystemMessage) | null) {
    if (!lastMessage) return false;
    if (lastMessage.role !== 'user') return false;
    if (timeDifference(new Date(lastMessage.timestamp), new Date()) < STREAM_RESPONSE_TIMEOUT_MS) {
        return false;
    }
    return true;
}

type AssistantContext = {
    store: AssistantStore;
    dispatch: React.Dispatch<ReducerPayload>;
}
export const defaultContextValue: AssistantContext = {
    store: {
        newAsk: [],
        liveConversations: [],
        list: [],
        state: { selectedCitations: {} }
    },
    dispatch: () => { },
}

export const AssistantStoreContext = createContext<AssistantContext>(defaultContextValue);


interface IProps {
    children: ReactNode;
}
function AssistantStoreProvider({ children }: IProps) {
    const [store, dispatch] = useReducer(assistantReducer, defaultContextValue.store);

    return (
        <AssistantStoreContext.Provider value={{ store, dispatch }}>
            {children}
        </AssistantStoreContext.Provider>
    )
}

export {
    AssistantStoreProvider,
};
