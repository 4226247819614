import { useContext } from "react"
import { CustomAlert } from "../CustomAlert"
import { ToastContext } from "@/contexts/ToastContext"

export const Toast = () => {
    const toastContext = useContext(ToastContext)

    return (
        <div className={`absolute top-[76px] right-0 pr-4 transition-transform duration-200 ease-in-out z-[40] ${toastContext.isToastShown ? 'translate-x-0' : 'translate-x-full'}`}>
            <CustomAlert
                className="max-w-[424px] [&_p]:max-w-[360px] overflow-hidden text-ellipsis"
                {...{
                    ...toastContext.shownToast,
                    onCloseClick: () => {
                        toastContext.shownToast?.onCloseClick?.()
                        toastContext.closeToast()
                    }
                }}
            />
        </div>
    )
}