import { getFileIcon, getIntegrationIcon } from "@/utils/components";
import { friendlyOrgName, getDomain, getHostname, getIconSrc, getIntegrationName, handleOpenLink } from "@/utils/utils";
import { ExternalLink } from "lucide-react";
import { TypographyLabel } from "./ui/Typography";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";
import Diffbot from "@/assets/Diffbot";
import { SourceDocumentMetadata } from "@/types/types";
import { MiddleTruncate } from '@re-dev/react-truncate'
import { formatDate } from "date-fns";
import FileText from "@/assets/FileText";

interface SourceProps {
    id: string
    url: string
    title?: string
    text?: string
    documentLink?: string
    showBorder?: boolean
    metadata?: SourceDocumentMetadata
}

interface SourceContentProps extends SourceProps {
    isSearchResult?: boolean
}

interface SourceHeaderProps extends SourceProps {
    isSearchResult?: boolean
}

export const Source = ({ id, url, title, text, documentLink, showBorder, metadata }: SourceProps) => {
    const style = `flex p-3 group ${showBorder ? 'border' : ''} border-system-border-light bg-system-surface hover:bg-system-hover rounded-sm cursor-pointer`

    return (
        <div key={`source_${id}`} className={style}
            onClick={() => {
                handleOpenLink({
                    id: id,
                    url: url,
                    title: title || '',
                    documentLink: documentLink || metadata?.external_link,
                    documentSource: metadata?.document_source,
                    window
                })
            }}
        >
            <div className="flex flex-col gap-2 flex-grow">
                <SourceHeader id={id} url={url} metadata={metadata} />
                <SourceContent id={id} url={url} text={text} title={title} metadata={metadata} />
            </div>
            <ExternalLink className="w-6 h-6 hidden shrink-0 group-hover:block" />
        </div>
    )
}

export const SourceHeader = ({ id, url, metadata, isSearchResult }: SourceHeaderProps) => {
    const { user } = useContext(UserContext)
    const isThirdPartyData = metadata?.label === 'third_party_data'
    const isDiffbot = metadata?.source?.toLowerCase().includes('diffbot')
    const isDocument = !id.includes("web")
    const isIntegration = metadata?.document_source === 'integration'
    const updatedDateString = metadata?.document_updated_at_desia && formatDate(new Date(metadata.document_updated_at_desia), 'd MMM yyyy')

    return (
        <div className={`flex gap-2 ${isSearchResult ? 'items-start mt-1' : 'items-center'}`}>
            <div>
                {isDiffbot ?
                    <div className={`${isSearchResult ? 'w-6 h-6' : 'w-4 h-4'} flex items-start justify-center rounded-[2px] border border-system-border-light`}>
                        <Diffbot />
                    </div>
                    :
                    isIntegration ?
                        <div className={`${isSearchResult ? 'w-6 h-6' : 'w-4 h-4'} flex items-center justify-center rounded-[2px] border border-system-border-light`}>
                            {getIntegrationIcon(metadata.document_source_details?.integration_code_name || '', true, isSearchResult ? 'w-4 h-4' : 'w-3 h-3')}
                        </div>
                        :
                        isDocument ?
                            <div className={`${isSearchResult ? 'w-6 h-6' : 'w-4 h-4'} flex items-center justify-center rounded-[2px] border border-system-border-light`}>
                                <FileText className={`${isSearchResult ? 'w-4 h-4' : 'w-3 h-3'} shrink-0`} />
                            </div>
                            :
                            <img src={getIconSrc(getHostname(url))} className={`${isSearchResult ? 'w-6 h-6' : 'w-4 h-4'} border border-system-border-light rounded-sm`} />
                }
            </div>
            <div className={isSearchResult ? "mt-[2px]" : ''}>
                {isThirdPartyData ?
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                        {isDiffbot ? 'DiffBot' : metadata.source}
                    </TypographyLabel>
                    :
                    isIntegration ?
                        <div className="flex flex-col gap-1">
                            <TypographyLabel className="text-system-body whitespace-pre-wrap overflow-hidden text-ellipsis">
                                {`${getIntegrationName(metadata.document_source_details?.integration_code_name || '')}${updatedDateString ? `, ${updatedDateString}` : ''}`}
                            </TypographyLabel>
                        </div>
                        :
                        isDocument ?
                            <TypographyLabel className="text-system-body whitespace-pre-wrap overflow-hidden text-ellipsis">
                                {`${friendlyOrgName(user?.app_metadata.organization_id)}${updatedDateString ? `${updatedDateString ? `, ${updatedDateString}` : ''}` : ''}`}

                            </TypographyLabel>
                            :
                            <TypographyLabel className="text-system-body whitespace-pre-wrap overflow-hidden text-ellipsis">
                                {getDomain(getHostname(url))}
                            </TypographyLabel>
                }
            </div>
        </div>
    )
}

export const SourceContent = ({ id, text, title, metadata, isSearchResult }: SourceContentProps) => {
    const isThirdPartyData = metadata?.label === 'third_party_data'
    const isDiffbot = metadata?.source?.toLowerCase().includes('diffbot')
    const isDocument = !id.includes("web")

    const style = `${isSearchResult ? 'font-body-strong' : 'font-label-strong'} text-system-primary whitespace-nowrap overflow-hidden text-ellipsis`

    const path = metadata?.document_source_path
    const formattedPath = 'in ' + path?.replaceAll('>', '/').split(' / ').slice(0, -1).join(' / ')
    const end = (formattedPath?.split('/').at(-1)?.length || 0) + 2

    return (
        <div className="flex gap-2 items-center">
            {isDocument && !isDiffbot && (
                getFileIcon(metadata?.document_type_friendly || '', 'w-6 h-6')
            )}
            <div className="flex flex-col gap-0 flex-grow max-w-[calc(100%-32px)]">
                <div className={style}>{isThirdPartyData ? text : title}</div>

                {path && (
                    <>
                        <MiddleTruncate
                            end={end}
                            className="w-full flex-grow font-label text-system-body hidden sm:!flex"
                        >
                            {formattedPath}
                        </MiddleTruncate>
                        <TypographyLabel className="w-full flex-grow font-label text-system-body flex sm:!hidden !line-clamp-1">
                            {formattedPath}
                        </TypographyLabel>
                    </>
                )}
            </div>
        </div>
    )
}