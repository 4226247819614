import { useNavigate } from "react-router-dom"
import { ReactNode, useContext, useEffect } from "react";
import { UserContext } from "@/contexts/UserContext";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "@/contexts/AssistantContext";
import { ChatSummary, DESIA_EVENT, ResponseChatList } from "@/types/types";
import { getTimestamp } from "@/utils/utils";
import { Main } from "./Main";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { Toast } from "../ui/toast";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { LayoutContext } from "@/contexts/LayoutContext";

type LayoutContainerProps = {
  component: ReactNode;
  tall?: boolean;
  scrollable?: boolean;
}

type LayoutProps = {
  component: ReactNode;
  email: string;
  threads: ChatSummary[];
  tall?: boolean;
  scrollable?: boolean;
}

export function LayoutContainer({ component, tall, scrollable }: LayoutContainerProps) {
  const { store, dispatch } = useContext(AssistantStoreContext);
  const { user } = useContext(UserContext);
  const { executeQuery } = useSocketQuery({
    event: DESIA_EVENT.CHAT_LIST,
    request: {
      requestId: "list",
      timestamp: getTimestamp(),
      params: {}
    },
    options: {
      manual: true,
      cacheTimeoutMs: 60_000,
      callback: (response) => {
        dispatch({
          action: ASSISTANT_ACTIONS.FETCH_THREADS,
          data: response.data as ResponseChatList[]
        })
      }
    }
  });

  useEffect(() => {
    if (user) {
      executeQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
          userId: user?.sub,
          requestId: "list",
          timestamp: getTimestamp(),
          params: {}
        },
      })
    }
  }, [user])

  return (
    <Layout
      component={component}
      email={user?.email || "..."}
      threads={store.list}
      tall={tall}
      scrollable={scrollable}
    />
  )
}

export function Layout({ component, tall, scrollable }: LayoutProps) {
  const layoutContext = useContext(LayoutContext)

  const navigate = useNavigate()

  const sidebarStyle = `${layoutContext.showSidebar ? 'translate-x-0' : '-translate-x-full'} bg-system-surface z-[20] border-r flex h-screen flex-col gap-10 fixed overflow-y-auto px-8 pt-6 pb-12 max-w-[320px] transition-all`

  useEffect(() => {
    // redirect user to a url after refresh, current use case is redirect to integration loading structure if the user refreshes the page
    const urlLock = localStorage.getItem(`url_lock`)

    if (urlLock) {
      navigate(urlLock)
    }
  }, [])

  return (
    <div className="flex w-full h-screen relative overflow-hidden">
      <div className="flex flex-col flex-grow bg">
        <Navbar />
        <Main component={component} tall={tall} scrollable={scrollable} />
      </div>
      {layoutContext.showSidebar && (
        <div className="fixed top-0 left-0 bg-black/[8%] backdrop-blur-sm w-full h-full" onClick={() => layoutContext.toggleShowSidebar(false)}></div>
      )}

      <div className={sidebarStyle}>
        <Sidebar />
      </div>

      <Toast />
    </div>
  )
}
