import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { ResourceTable } from "../Resources/ResourceTable";
import { DocumentStoreContext } from "@/contexts/DocumentContext";
import { QueryStatus } from "@/types/types";
import { useContext, useEffect, useState } from "react";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import { UserContext } from "@/contexts/UserContext";
import { ChevronLeft, Sparkles, X } from "lucide-react";
import { plural } from "@/utils/utils";
import Stack from "@/assets/Stack";
import { CustomTooltip } from "../CustomTooltip";

const DocumentSelectorDialog = () => {
    const documentContext = useContext(DocumentStoreContext);
    const { settings, updateSettings } = useContext(UserContext);

    const [searchText, setSearchText] = useState('')
    const [tempSources, setTempSources] = useState(settings.assistant.sources)
    const [showSuggestions, setShowSuggestions] = useState(true)

    const loading = documentContext.store.status === QueryStatus.FETCHING;
    const errorFetching = documentContext.store.status === QueryStatus.ERROR_FETCHING;
    const resources = documentContext.store.data || [];
    const handleListFiles = documentContext.handlers.listDocuments;

    const filteredDocuments = resources.filter((doc) => {
        if (!searchText) { return true }
        const splitText = searchText.toLowerCase().split(' ')
        return splitText.some((text) => doc.document_name.toLowerCase().includes(text))
    })

    // @ts-expect-error
    const selectedFiles = settings.assistant.sources.find((v) => v.id === 'internal-search')?.include || []
    // @ts-expect-error
    const tempSelectedFiles = tempSources.find((v) => v.id === 'internal-search')?.include || []
    const selectedDocuments = resources.filter((doc) => tempSelectedFiles.includes(doc.document_name))
    const suggestedDocuments = [...resources].splice(0, 5)

    useEffect(() => {
        handleListFiles()
        setTempSources(settings.assistant.sources)
    }, [])


    const toggleTempFileSelection = (value: string) => {
        // @ts-expect-error
        const currentSelectedFiles = tempSources.find((v) => v.id === 'internal-search')?.include || []
        const otherSources = tempSources.filter((v) => v.id !== 'internal-search')
        const selected = currentSelectedFiles.includes(value)

        if (!selected) {
            setTempSources([...otherSources, { id: 'internal-search', include: [...currentSelectedFiles, value] }])
        } else {
            setTempSources([...otherSources, { id: 'internal-search', include: currentSelectedFiles.filter((v: string) => v !== value) }])
        }
    }

    const saveSettings = () => {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: tempSources
                }
            }
        })
    }

    return (
        <>
            <Dialog onOpenChange={() => { setTempSources(settings.assistant.sources) }}>
                <DialogTrigger asChild>
                    <Button className="mt-[2px]" variant={'inline'} data-tooltip-id="select-files">
                        <Stack />
                    </Button>
                </DialogTrigger>
                <DialogContent className="rounded-md !max-w-[calc(100%-48px)] tablet:!max-w-[951px] max-h-[calc(100vh-32px)] overflow-auto">
                    <DialogHeader>
                        <DialogTitle className="text-left">Select which documents to use in Ask</DialogTitle>
                        <DialogDescription className="font-body text-left">
                            Search for the files you want to add or use the suggestions below.
                        </DialogDescription>
                        <div className="flex flex-col gap-10 pt-10">
                            {searchText && selectedDocuments.length > 0 && (
                                <Button variant="tertiary" className="mt-10" onClick={() => setSearchText('')}>
                                    <div className="flex gap-2">
                                        <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
                                        <TypographyBody isStrong={true}>
                                            {`${selectedDocuments.length} ${plural('file', selectedDocuments.length)} selected`}
                                        </TypographyBody>
                                    </div>
                                </Button>
                            )}

                            <div className="mobile:mx-auto">
                                <Input
                                    className="w-full mobile:w-[320px]"
                                    placeholder="Search by file name"
                                    isSearch={true}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)

                                        if (showSuggestions) {
                                            setShowSuggestions(false)
                                        }
                                    }}
                                    isCloseVisible={!!searchText}
                                    onCloseClick={() => setSearchText('')}
                                />
                            </div>

                            {searchText && (
                                <div className="max-h-[375px] overflow-y-auto">
                                    <ResourceTable
                                        documents={filteredDocuments}
                                        loading={loading}
                                        error={errorFetching}
                                        askSelection={true}
                                        selected={tempSelectedFiles}
                                        onSelect={toggleTempFileSelection}
                                    />
                                </div>
                            )}

                            {!searchText && selectedDocuments.length > 0 && (
                                <div className="flex flex-col gap-4">
                                    <TypographyBody className="text-system-body">
                                        {`${selectedDocuments.length} ${plural('file', selectedDocuments.length)} selected`}
                                    </TypographyBody>

                                    <div className="max-h-[375px] overflow-y-auto">
                                        <ResourceTable
                                            documents={selectedDocuments}
                                            loading={loading}
                                            error={errorFetching}
                                            askSelection={true}
                                            selected={tempSelectedFiles}
                                            onSelect={toggleTempFileSelection}
                                        />
                                    </div>
                                </div>
                            )}

                            {showSuggestions && !searchText && (
                                <div className={`flex flex-col gap-6 p-4 bg-[#F5F8FA] rounded-lg ${loading ? 'max-w-full' : '!max-w-[calc(100vw-96px)]'}`}>
                                    <div className="flex gap-2.5 items-center">
                                        <Sparkles className="w-5 h-5 stroke-[1.5px]" />
                                        <TypographyBody className="">Suggested</TypographyBody>

                                        <Button variant={'tertiary'} className="ml-auto" onClick={() => setShowSuggestions(false)}>
                                            <X className="w-6 h-6 stroke-[1.5px]" />
                                        </Button>
                                    </div>
                                    <div className="max-h-[375px] overflow-y-auto">
                                        <ResourceTable
                                            documents={suggestedDocuments}
                                            loading={loading}
                                            error={errorFetching}
                                            askSelection={true}
                                            selected={tempSelectedFiles}
                                            onSelect={toggleTempFileSelection}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {(tempSelectedFiles.length > 0 || selectedFiles.length > 0) && (
                            <DialogFooter className="pt-10">
                                <DialogClose>
                                    <Button variant="secondary" className="w-full">Cancel</Button>
                                </DialogClose>
                                <DialogClose>
                                    <Button className="w-full" onClick={() => saveSettings()}>
                                        Confirm selection
                                    </Button>
                                </DialogClose>
                            </DialogFooter>
                        )}
                    </DialogHeader>
                </DialogContent>
            </Dialog>
            <CustomTooltip
                id="select-files"
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Select files
                </TypographyLabel>
            </CustomTooltip>
        </>
    )
}

export default DocumentSelectorDialog