import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { useRef, useState } from "react"
import { DialogClose } from "@radix-ui/react-dialog"
import { CustomAlert } from "../CustomAlert"
import { ASYNC_STATUS, ResponseSignedUrlUpload, ResponseSignedUrlsUpload } from "@/types/types"
import { MAX_UPLOAD_FILES, WEB_SERVER_ENDPOINT } from "@/constants"
import { Badge } from "../ui/badge"
import { TypographyBody, TypographyLabel } from "../ui/Typography"
import { FileUp } from "lucide-react"

type UploadableFile = {
  file: File,
  signedUpload: ResponseSignedUrlUpload | null,
  skipUpload?: boolean,
  upload: {
    skip?: boolean,
    status: ASYNC_STATUS,
  }
}

function SelectedFile({ file, handleSkipUpload }: { file: UploadableFile, handleSkipUpload: (file: File) => void }) {
  const fileExists = file.signedUpload?.exists;
  const classes = "min-h-[40px] text-nowrap max-w-[200px]";
  const filenameClasses = "text-system-body overflow-hidden";

  return (
    <div className={classes}>
      {fileExists ? <Badge variant="blue" className="mr-2">duplicate</Badge> : ""}
      {fileExists ? <span><Button variant="inline" size={"sm"} className="mr-2" onClick={() => { handleSkipUpload(file.file) }}>Skip</Button></span> : ""}
      <span className={filenameClasses}>{file.file.name}</span>
    </div>
  )
}

export function MultiFileUpload() {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadableFile[] | null>(null);
  const [dragOver, setDragOver] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null)

  async function getSignedUrls(fileNames: string[]): Promise<ResponseSignedUrlsUpload> {
    const res = await fetch(`${WEB_SERVER_ENDPOINT}/document/upload-signed-urls`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileNames }),
      credentials: "include",
    });
    return await res.json();
  }


  async function uploadSignedFile(file: File, signedUpload: ResponseSignedUrlUpload): Promise<string> {
    const res = await fetch(signedUpload.signed_url, {
      method: 'put',
      headers: {
        ...signedUpload.headers,
        "Content-Type": "application/octet-stream",
      },
      body: file,
    });
    if (!res.ok) {
      throw new Error("failed to upload file");
    }
    return await res.text();
  }

  function handleFileChange(files: FileList | null) {
    if (!files) return;
    if (files.length > MAX_UPLOAD_FILES) {
      alert(`You can only upload ${MAX_UPLOAD_FILES} files at a time`);
      return;
    }
    const uploadableFiles = Array.from(files).map(file => ({ file, signedUpload: null, upload: { status: ASYNC_STATUS.idle } }));
    setSelectedFiles(uploadableFiles);
  }

  function updateFileStatus(file: File, status: ASYNC_STATUS) {
    setSelectedFiles((prev) => {
      if (!prev) return prev;
      return prev.map((f) => {
        if (f.file.name === file.name) {
          return { ...f, upload: { status } };
        }
        return f;
      });
    });
  }

  async function uploadFiles() {
    if (!selectedFiles) return;
    toggleDialog();

    const fileNames = selectedFiles.map((f) => f.file.name);
    let updatedFiles;

    try {
      const response = await getSignedUrls(fileNames);
      updatedFiles = selectedFiles.map((file) => {
        const signedUpload =
          response.signed_urls.find((s) => s.file_name === file.file.name) || null;
        return { ...file, signedUpload };
      });
      setSelectedFiles(updatedFiles);
    } catch (error) {
      console.error(error);
      return;
    }

    const uploadPromises = updatedFiles.map(async (s) => {
      if(s.skipUpload || !s.signedUpload) {
        return true
      }
      updateFileStatus(s.file, ASYNC_STATUS.loading);
      try {
        await uploadSignedFile(s.file, s.signedUpload);
        updateFileStatus(s.file, ASYNC_STATUS.success);
        return true;
      } catch (error) {
        console.error(error);
        updateFileStatus(s.file, ASYNC_STATUS.error);
        return false;
      }
    });

    await Promise.all(uploadPromises);
    // TODO: define what to do when some of these promises fail, show a toast?
  }

  function toggleDialog() {
    if (showDialog) {
      // reset state
      setSelectedFiles(null);
    }
    setShowDialog((prev) => !prev);
  }

  function handleSkipUpload(file: File) {
    setSelectedFiles((prev) => {
      if (!prev) return prev;
      return prev.map((f) => {
        if (f.file.name === file.name) {
          return { ...f, skipUpload: true };
        }
        return f;
      });
    });
  }

  const computedSelectedFiles = (selectedFiles || []).filter(f => f.skipUpload !== true);
  const uploadDisabled = computedSelectedFiles.length === 0;
  const countFiles = computedSelectedFiles.length;
  const ctaLabel = `Upload files ${countFiles > 0 ? `(${countFiles})` : ""}`;
  return (
    <>
      <div
        ref={containerRef}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2394A3B8FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
        className={`flex flex-col gap-6 ${dragOver ? 'bg-system-hover' : 'bg-system-surface'} p-6 border-system-placeholder rounded-[12px]`}
        onDragLeave={(e) => {
          e.preventDefault()
          e.stopPropagation()

          const rect = containerRef.current?.getBoundingClientRect();
          if (!rect) return
          if (
            e.clientX < rect.left ||
            e.clientX > rect.right ||
            e.clientY < rect.top ||
            e.clientY > rect.bottom
          ) {
            setDragOver(false)
          }
        }}
        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setDragOver(true)
        }}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setDragOver(false)
          setShowDialog(true)
          handleFileChange(e.dataTransfer.files)
        }}
      >
        <div className="flex flex-col gap-2 text-center">
          <TypographyBody isStrong={true}>
            Enhance the platform with internal knowledge
          </TypographyBody>

          <TypographyLabel className="text-system-body whitespace-pre-wrap">
            {`To expand and take advantage of Desia's capabilities,\ndrag and drop files here or use the button below`}
          </TypographyLabel>
        </div>

        <div className="w-fit mx-auto">

          <Dialog open={showDialog} onOpenChange={toggleDialog}>
            <DialogTrigger asChild>
              <Button>
                <div className="flex gap-2">
                  <FileUp className="w-6 h-6 shrink-0 stroke-[1.5px]" />

                  Upload files
                </div>
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Upload files</DialogTitle>
                <DialogDescription>
                </DialogDescription>
              </DialogHeader>
              <div className="flex flex-col gap-10 my-4">
                <div className="flex flex-col gap-4">
                  <label htmlFor="file-upload">
                    <Button variant='secondary' className="pointer-events-none cursor-pointer">
                      Select files
                    </Button>
                  </label>
                  <input id="file-upload" type="file" name="file" onChange={(e) => handleFileChange(e.currentTarget.files)} className="hidden-file-input" multiple={true} />

                  <div className="max-h-[255px] overflow-y-scroll">
                    {(computedSelectedFiles).map(s => (
                      <SelectedFile key={s.file.name} file={s} handleSkipUpload={handleSkipUpload} />
                    ))}
                  </div>
                </div>
                <div>
                  <CustomAlert variant="info" description="Uploaded files will be accessible by anyone within your company" />
                </div>
              </div>
              <DialogFooter className="flex flex-row justify-end gap-2 sm:gap-[unset]">
                <DialogClose>
                  <Button variant={"secondary"}>Cancel</Button>
                </DialogClose>
                <Button type="submit" onClick={uploadFiles} disabled={uploadDisabled}>{ctaLabel}</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>

    </>
  )
}
