import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { ResponseDocument } from "@/types/types"
import { Skeleton } from "../ui/skeleton";
import { formatBytes, getFileLinkV2, getIntegrationName } from "@/utils/utils";
import { ErrorMessage } from "../ErrorMessage";
import { getFileIcon } from "@/utils/components";
import { Checkbox } from "../ui/checkbox";
import { TypographyBody } from "../ui/Typography";
import { Badge } from "../ui/badge";
import { useState } from "react";
import { formatDate } from "date-fns";
import { Button } from "../ui/button";
import { EllipsisVertical, FileSearch } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { DeleteResourceDialog } from "./DeleteResourceDialog";
import { NotAvailableDialog } from "./NotAvailableDialog";

function getDirectoryFromDocument(d: ResponseDocument, organizationName: string) {
    if (d.document_is_part_of_desia_library) {
        return "Desia library";
    }

    if (d.document_source === 'integration') {
        return getIntegrationName(d.document_source_details?.integration_code_name || '')
    }

    return organizationName;
}

function getCreatedAt(d: ResponseDocument) {
    // manual upload: d.created_at_desia
    // uploaded via integration: d?.document_source_details?.integration_created_at 
    // fallback (document created at) d.document_created_at
    const date =  d.created_at_desia  || d?.document_source_details?.integration_created_at || d.document_created_at;
    if (!date) return null;
    return formatDate(new Date(date), 'd MMM yyyy')
}

function getUpdatedAt(d: ResponseDocument) {
    const date = d.document_updated_at || d.updated_at_desia
    if (!date) return null;
    return formatDate(new Date(date), 'd MMM yyyy')
}

export function ResourceTable({ documents, loading, error, askSelection, selected, onSelect }: { documents: ResponseDocument[], loading: boolean, error: boolean, askSelection?: boolean, selected?: string[], onSelect?: (value: string) => void }) {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [fileToDelete, setFileToDelete] = useState<ResponseDocument | null>(null)
    const [showAnalyseDialog, setShowAnalyseDialog] = useState(false)

    const areAllDocumentsReady = documents.every((d) => d.document_is_ready_to_use)

    const getBadge = (status: string) => {
        switch (status) {
            case "uploading": return <Badge variant="teal" className="whitespace-nowrap">Uploading</Badge>
            case "available":
            case "failed": return <Badge variant="red" className="whitespace-nowrap">Failed to process</Badge>
            case "normalizing":
            case "chunking":
            case "embedding":
            case "entity_recognition":
            default: return <Badge variant="purple" className="whitespace-nowrap">Processing</Badge>
        }
    }
    return (
        <div>
            {!loading && !error && (
                <Table className="max-w-full">
                    <TableHeader>
                        <TableRow>
                            {askSelection && (
                                <TableHead className="font-body-strong text-system-primary"></TableHead>
                            )}
                            <TableHead className="font-body-strong text-system-primary w-[calc(100%-154px-120px-161px)] shrink">File name</TableHead>
                            {askSelection ?
                                <>
                                    <TableHead className="font-body-strong text-system-primary whitespace-nowrap shrink-0">Directory</TableHead>
                                    <TableHead className="font-body-strong text-system-primary whitespace-nowrap shrink-0">Updated on</TableHead>
                                </>
                                :
                                <>
                                    <TableHead className="font-body-strong text-system-primary w-[154px] whitespace-nowrap shrink-0">Directory</TableHead>
                                    <TableHead className="font-body-strong text-system-primary w-[120px] whitespace-nowrap shrink-0">File size</TableHead>
                                    <TableHead className="font-body-strong text-system-primary w-[161px] whitespace-nowrap shrink-0">Updated on</TableHead>
                                    <TableHead className="font-body-strong text-system-primary w-[56px] shrink-0"></TableHead>
                                </>
                            }
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {documents.map((d) => {
                            const canBeDeleted = d.document_source !== 'integration' && d.document_is_part_of_desia_library !== true
                            return (
                                <TableRow key={d.document_id} className="!max-w-full">
                                    {askSelection && (
                                        <TableCell className="font-label text-system-body">
                                            <Checkbox
                                                checked={selected?.includes(d.document_name)}
                                                disabled={false}
                                                onCheckedChange={() => onSelect?.(d.document_name)}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell className="font-label text-system-body group hover:cursor-pointer max-w-[500px] !overflow-hidden" onClick={() => {
                                        const link = getFileLinkV2(d);
                                        if (!link) {
                                            alert("We failed to get the file link, please try again shortly");
                                        };
                                        window.open(link, '_blank');
                                    }}>
                                        <div className="flex gap-2 items-center">
                                            {getFileIcon(d.document_type_friendly, 'w-6 h-6')}
                                            {askSelection ?
                                                <TypographyBody className="whitespace-nowrap overflow-hidden text-ellipsis group-hover:underline">
                                                    {d.document_name}
                                                </TypographyBody>
                                                :
                                                <div className="flex gap-2 w-[calc(100%-24px)]">
                                                    <TypographyBody className="whitespace-nowrap overflow-hidden text-ellipsis group-hover:underline !shrink">
                                                        {d.document_name}
                                                    </TypographyBody>
                                                    {!areAllDocumentsReady && !d.document_is_ready_to_use && (
                                                        <div className="w-fit shrink-0 mt-0.5">
                                                            {getBadge(d.document_processing_status)}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </TableCell>

                                    {askSelection ?
                                        <>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">{getDirectoryFromDocument(d, 'Shared')}</TableCell>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">{getUpdatedAt(d) || getCreatedAt(d)}</TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">{getDirectoryFromDocument(d, 'Shared')}</TableCell>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">{formatBytes(d.document_size_bytes || 0)}</TableCell>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">{getUpdatedAt(d) || getCreatedAt(d)}</TableCell>
                                            <TableCell className="font-label text-system-body whitespace-nowrap shrink-0">
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger className="flex items-center">
                                                        <Button variant='tertiary'>
                                                            <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                                                        </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent align="start">
                                                        <DropdownMenuItem
                                                            variant='tertiary'
                                                            onClick={() => {
                                                                setShowAnalyseDialog(true)
                                                            }}>
                                                            <div className="flex gap-2">
                                                                <FileSearch className="w-6 h-6 shrink-0 stroke-[1.5px]" />

                                                                Analyse
                                                            </div>
                                                        </DropdownMenuItem>
                                                        {canBeDeleted && (
                                                            <>
                                                                <DropdownMenuSeparator />
                                                                <DropdownMenuItem
                                                                    variant='tertiary-destructive'
                                                                    onClick={() => {
                                                                        setFileToDelete(d)
                                                                        setShowDeleteDialog(true)
                                                                    }}>
                                                                    Delete file
                                                                </DropdownMenuItem>
                                                            </>
                                                        )}
                                                    </DropdownMenuContent>
                                                </DropdownMenu>

                                            </TableCell>
                                        </>
                                    }


                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )}

            {loading && (
                <div>
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                </div>
            )}

            {!loading && error && (
                <ErrorMessage message="We failed to list resources, please try again shortly" />
            )}

            <DeleteResourceDialog resource={fileToDelete} open={showDeleteDialog} setOpen={(v) => setShowDeleteDialog(v)} />
            <NotAvailableDialog open={showAnalyseDialog} setOpen={(v) => setShowAnalyseDialog(v)} />
        </div>
    )
}
