import { DedupedSearchQueryItem } from "@/types/types";
import { handleOpenLink } from "@/utils/utils";
import Markdown from "markdown-to-jsx";
import { ReactElement, useState } from "react";
import { Card } from "../ui/card";
import { ChevronLeft, ChevronRight, FileSearch } from "lucide-react";
import { Button } from "../ui/button";
import { SourceContent, SourceHeader } from "../Source";
import { TypographyLabel } from "../ui/Typography";
import Divider from "../ui/divider";
import { ActionMenu } from "../ActionMenu";
import ExternalLink from "@/assets/ExternalLink";
import { CustomTooltip } from "../CustomTooltip";

function Highlight(props: { children: ReactElement }) {
    return <span className="font-medium text-system-primary citation-background">{props.children}</span>
}

export function SearchResult({ resource: d, onAnalyseClick }: { resource: DedupedSearchQueryItem, onAnalyseClick: () => void }) {
    const [shownExtract, setShownExtract] = useState(0);
    const reducedHighlights = d.extracts.flatMap((v) => v.highlight?.passage_text || '').filter((v) => v)

    return (
        <>
            <Card key={`search_result_card_${d.id}`} className="p-3 shadow-none group w-full">
                <div className=''>
                    <div className='flex flex-col gap-2'>
                        <div className="flex flex-wrap gap-2 mobile:items-start">
                            <div className="flex-grow">
                                <SourceHeader
                                    id={d.id}
                                    url={d.url}
                                    title={d.title}
                                    documentLink={d.document_link}
                                    metadata={{
                                        document_is_part_of_desia_library: d.document_is_part_of_desia_library,
                                        document_type_friendly: d.document_type_friendly,
                                        document_updated_at_desia: d.document_updated_at_desia,
                                        document_source: d.document_source,
                                        document_source_details: d.document_source_details,
                                        document_source_path: d.document_source_path
                                    }}
                                    isSearchResult={true}
                                />
                            </div>
                            <div className="flex gap-3 items-center md:shrink-0 min-h-8">
                                <TypographyLabel className="text-system-body">
                                    {`${shownExtract + 1}/${reducedHighlights.length} extracts`}
                                </TypographyLabel>

                                <div className="flex gap-1">
                                    {shownExtract > 0 && (
                                        <Button variant='secondary' size='icon' onClick={() => setShownExtract(v => v - 1)}>
                                            <ChevronLeft className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                                        </Button>
                                    )}

                                    {shownExtract < reducedHighlights.length - 1 && (
                                        <Button variant='secondary' size='icon' onClick={() => setShownExtract(v => v + 1)}>
                                            <ChevronRight className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Divider />
                        <div className={`flex flex-col ${d.document_source_path ? 'gap-6' : 'gap-2'}`}>
                            <div className="relative min-h-[42px] flex items-center">
                                <div className="flex-grow w-full max-w-full">
                                    <SourceContent
                                        id={d.id}
                                        url={d.url}
                                        title={d.title}
                                        documentLink={d.document_link}
                                        metadata={{
                                            document_is_part_of_desia_library: d.document_is_part_of_desia_library,
                                            document_type_friendly: d.document_type_friendly,
                                            document_updated_at_desia: d.document_updated_at_desia,
                                            document_source: d.document_source,
                                            document_source_details: d.document_source_details,
                                            document_source_path: d.document_source_path
                                        }}
                                        isSearchResult={true}
                                    />
                                </div>
                                <ActionMenu
                                    className="!px-2 !shadow-none ml-auto hidden group-hover:flex absolute top-auto right-0"
                                    buttons={[
                                        {
                                            variant: 'tertiary',
                                            children: <FileSearch className="w-6 h-6 stroke-[1.5px] shrink-0 !stroke-link" />,
                                            size: 'icon',
                                            "data-tooltip-id": `analyse-source-tooltip-${d.id}`,
                                            onClick: () => onAnalyseClick()
                                        },
                                        {
                                            variant: 'tertiary',
                                            children: <ExternalLink className="w-6 h-6 stroke-[1.5px] shrink-0 !stroke-link" />,
                                            size: 'icon',
                                            "data-tooltip-id": `open-source-link-tooltip-${d.id}`,
                                            onClick: () => handleOpenLink({
                                                id: d.id,
                                                url: d.url,
                                                title: d.title || '',
                                                documentLink: d.document_link,
                                                documentSource: d.document_source,
                                                window
                                            })
                                        },
                                    ]}
                                />
                            </div>
                            <div className="">
                                <div className={`text-system-body font-label`}>
                                    <Markdown options={{
                                        overrides: {
                                            em: {
                                                component: Highlight
                                            },
                                        },
                                        wrapper: 'span',
                                        forceBlock: true,
                                    }}>
                                        {reducedHighlights[shownExtract]}
                                    </Markdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card >
            <CustomTooltip
                id={`analyse-source-tooltip-${d.id}`}
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Analyse
                </TypographyLabel>
            </CustomTooltip>
            <CustomTooltip
                id={`open-source-link-tooltip-${d.id}`}
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Open
                </TypographyLabel>
            </CustomTooltip>
        </>

    )
}
