import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/shadcn/utils";
import Checkmark from "@/assets/Checkmark";

const buttonVariants = cva(
  "flex gap-2 font-body-strong inline-flex items-center justify-center whitespace-nowrap rounded-sm transition-colors disabled:pointer-events-none",
  {
    variants: {
      variant: {
        "primary": "[&_svg]:stroke-system-surface bg-system-primary text-system-surface px-3 py-2 hover:bg-system-body active:inner-border-4 inner-border-system-primary focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:bg-system-border-regular",
        "secondary": "[&_svg]:stroke-system-primary border border-system-border-regular inner-border-system-border-regular bg-system-secondary text-system-primary px-3 py-2 hover:bg-system-hover active:inner-border-2 focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:border-2 disabled:text-system-border-regular",
        "tertiary": "[&_svg]:stroke-system-primary text-system-primary w-fit border-2 border-transparent hover:border-system-hover active:border-system-hover active:border-system-border-light hover:bg-system-hover active:bg-system-border-light focus-visible:border-system-border-light focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:bg-system-border-light disabled:text-system-border-regular",
        "primary-destructive": "[&_svg]:stroke-system-surface bg-messaging-negative-text text-system-surface px-3 py-2 hover:bg-[#76091D] active:inner-border-2 inner-border-system-body focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-messaging-negative-text focus-visible:ring-offset-1 disabled:bg-system-border-regular",
        "secondary-destructive": "[&_svg]:stroke-messaging-negative-text border border-system-border-regular inner-border-system-border-regular bg-system-secondary text-messaging-negative-text px-3 py-2 hover:bg-system-hover active:inner-border-2 focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:border-2 disabled:text-system-border-regular",
        "tertiary-destructive": "[&_svg]:stroke-messaging-negative-text text-messaging-negative-text w-fit border-2 border-transparent hover:border-system-hover active:border-system-hover active:border-system-border-light hover:bg-system-hover active:bg-system-border-light focus-visible:border-system-border-light focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:bg-system-border-light disabled:text-system-border-regular",
        "inline": "[&_svg]:stroke-link rounded-none text-link w-fit px-[2px] border-transparent border-b-2 hover:border-link active:bg-system-hover focus-visible:rounded-[2px] focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:bg-system-hover disabled:text-system-border-regular",
        "tag": "!font-label rounded-full text-system-body bg-system-secondary w-fit px-3 inner-border inner-border-system-body hover:bg-system-hover active:bg-system-hover active:inner-border-system-border-regular active:inner-border-2 focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:bg-system-hover focus-visible:inner-border-system-border-regular focus-visible:inner-border-2 disabled:text-system-border-regular disabled:inner-border-system-border-regular",
        "tag-selected": "!font-label rounded-full text-system-primary w-fit pl-1 pr-3 inner-border-[1.5px] inner-border-system-primary bg-system-hover focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 focus-visible:bg-system-hover focus-visible:inner-border-system-border-regular focus-visible:inner-border-2 disabled:text-system-border-regular disabled:inner-border-system-border-regular disabled:bg-system-secondary",
      },
      size: {
        default: "max-h-10",
        fit: '',
        sm: "h-9",
        lg: "h-11",
        icon: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {variant === 'tag-selected' ?
          <div className="flex gap-1 items-center">
            <Checkmark className="w-6 h-6 shrink-0" />
            {children}
          </div>
          :
          children
        }
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
