import { Link, useNavigate } from "react-router-dom";
import { TypographyBody } from "../ui/Typography";
import LeftToLine from "@/assets/LeftToLine";
import { useContext } from "react";
import { LayoutContext } from "@/contexts/LayoutContext";
import logo from "../../assets/logo.svg";
import Divider from "../ui/divider";
import { UserMenu } from "./UserMenu";
import { handleLogout } from "@/utils/utils";
import { Searchbar } from "../Searchbar";

const baseStyles = "flex items-center gap-2 transition-all";

export function Sidebar() {
    const layoutContext = useContext(LayoutContext)
    const navigate = useNavigate()

    const handleSearch = (query: string) => {
        navigate(`/search?q=${query}`);
    }

    return (
        <nav className="flex flex-col gap-12 items-start font-medium h-full">
            <div className="flex flex-col gap-10 w-full">
                <div className="flex gap-2 cursor-pointer" onClick={() => layoutContext.toggleShowSidebar(false)}>
                    <LeftToLine className="w-6 h-6 shrink-0 stroke-system-primary" />

                    <TypographyBody isStrong={true}>
                        Hide
                    </TypographyBody>
                </div>

                <img className="w-[133px] h-[43px]" src={logo} />
                
                <Searchbar type='sidebar' handleSearch={handleSearch} />
            </div>

            <div className="flex flex-col gap-6">
                <Link
                    to="/assistant/ask"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Ask Desia
                    </TypographyBody>
                </Link>
                <Link
                    to="/reports"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Reports
                    </TypographyBody>
                </Link>
                <Link
                    to="/library"
                    className={`${baseStyles} text-system-primary`}
                >
                    <TypographyBody isStrong={true}>
                        Library
                    </TypographyBody>
                </Link>
            </div>

            <div className="flex flex-col gap-10 w-full">
                <Divider />

                <div className="w-fit">
                    <UserMenu showMenu={false} />
                </div>

                <div className="flex flex-col gap-6">
                    <Link
                        to="/profile"
                        className={`${baseStyles} text-system-primary`}
                    >
                        <TypographyBody isStrong={true}>
                            Profile
                        </TypographyBody>
                    </Link>

                    <Link
                        to="/integrations"
                        className={`${baseStyles} text-system-primary`}
                    >
                        <TypographyBody isStrong={true}>
                            Integrations
                        </TypographyBody>
                    </Link>

                    <TypographyBody className="cursor-pointer" isStrong={true} onClick={() => handleLogout()}>
                        Sign out
                    </TypographyBody>
                </div>
            </div>
        </nav>
    )
}