import { useEffect, useState, useRef, useContext } from "react";
import { SelectedCitations, SourceDocumentId, getStreamStatus } from "../../contexts/AssistantContext";
import { AskTextbox } from "../AskTextbox";
import { UserChatMessage, SystemChatMessage, getMessageId } from "../Chat";
import { ErrorMessage } from "../ErrorMessage";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { AssistantAskMode, ChatMessage, QueryState, SourceConnector, SystemMessage } from "../../types/types";
import { checkUserFlag } from "../../utils/utils";
import { ChatBox } from "./ChatBox";
import { UserContext } from "@/contexts/UserContext";
import Divider from "../ui/divider";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { Button } from "../ui/button";
import { ChevronLeft } from "lucide-react";

interface IProps {
    handleAsk: (args: { message: string, mode: AssistantAskMode }) => void;
    selectCitation: (messageId: string, documentId: SourceDocumentId[]) => void;
    showAllCitations: (messageId: string) => void;
    conversation: ChatMessage[];
    queryState: QueryState;
    selectedCitations: SelectedCitations;
    sources: SourceConnector[]
}

export function Conversation({
    handleAsk,
    selectCitation,
    showAllCitations,
    conversation,
    queryState,
    selectedCitations,
    sources,
}: IProps) {
    const { settings, updateSettings } = useContext(UserContext);

    const [hoverCitations, setHoverCitations] = useState<SourceDocumentId[]>([]);
    const [hoverDocument, setHoverDocument] = useState<SourceDocumentId>("");
    const [hasRendered, setHasRendered] = useState(false);
    const [isChatBoxCoveringText, setIsChatBoxCoveringText] = useState(false);
    const [shouldResetBlocker, setShouldResetBlocker] = useState(true);

    const location = useLocation();
    const blocker = useBlocker(({ currentLocation, nextLocation }) => streamStatus !== 'Ready' && conversation.at(-1)?.role === 'system' && currentLocation.pathname !== nextLocation.pathname);

    const navigate = useNavigate();
    const conversationBottomMarginRef = useRef<HTMLDivElement>(null);
    const conversationBottomRef = useRef<HTMLDivElement>(null);
    const lastMessage = conversation.length > 0 ? conversation[conversation.length - 1] : null;

    const streamStatus = getStreamStatus(lastMessage, sources);
    const askExpired = false; // fixme: checkAskExpired(lastMessage);
    const isBusy = (streamStatus !== 'Ready') && (!queryState.error) && !askExpired;

    useEffect(() => {
        if (conversation.length > 0 && !isBusy && !hasRendered) {
            conversationBottomRef.current?.scrollIntoView({ behavior: 'instant', block: 'end' })
            setHasRendered(true)
        }
    }, [conversation])

    useEffect(() => {
        setHoverCitations([]);
        setHoverDocument("");
        setHasRendered(false)
    }, [location.pathname])

    useEffect(() => {
        const handleScroll = () => {
            const scrolledTo = window.scrollY + window.innerHeight
            const clientWidth = document.body.clientWidth
            const emptyHeight = clientWidth > 568 ? clientWidth > 768 ? 160 : 80 : 40

            if (document.body.scrollHeight - emptyHeight < scrolledTo) {
                setIsChatBoxCoveringText(true)
            } else {
                setIsChatBoxCoveringText(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])


    useEffect(() => {
        const lastUserMessage = [...conversation].reverse().find((v) => v.role === 'system') as SystemMessage

        let mode = settings.assistant.mode

        if (lastUserMessage?.data.plan) {
            mode = 'expert'
        } else {
            mode = 'simple'
        }

        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    mode: mode
                }
            }
        })
    }, [conversation])

    const hoverEffects = checkUserFlag('assistant: highlight citations');

    const isLegacyDesign = checkUserFlag("ui: legacy design system");
    const expertModeAvailable = true;

    const chatboxContainerStyle = `w-[calc(100%-64px)] sm:w-full fixed bottom-0 left-0 right-0 sm:left-auto sm:right-auto max-w-[calc(100%-64px)] md:max-w-[710px] mx-8 sm:mx-0`

    return (
        <>
            <div className="block [@media(min-width:1170px)]:flex gap-4">
                <Button variant='tertiary' className="mb-4 [@media(min-width:1170px)]:mb-0 ml-0 lg:ml-20 h-fit" onClick={() => navigate('/assistant/ask')}>
                    <div className="flex gap-2">
                        <ChevronLeft className="h-6 w-6 stroke-[1.5px] shrink-0" />

                        Ask Desia chats
                    </div>
                </Button>

                <div className={`${isLegacyDesign ? "chat-component-container" : "mx-auto"} max-w-[710px] md:w-[710px]`}>
                    <div className={`${isLegacyDesign ? "chat-messages" : ""}`}>
                        {conversation
                            .map((chatMessage, idx) => {
                                if (chatMessage.role === 'user') {
                                    return <UserChatMessage key={`user__${idx}`} message={chatMessage} />
                                }
                                if (chatMessage.role == 'system') {
                                    const messageId = getMessageId(chatMessage, idx);

                                    return (
                                        <>
                                            <SystemChatMessage
                                                key={`system_${idx}`}
                                                messageId={messageId}
                                                message={chatMessage}
                                                selectedCitations={selectedCitations[messageId] || []}
                                                onSelectCitation={selectCitation}
                                                onShowAllCitations={showAllCitations}
                                                hoveredCitations={hoverCitations}
                                                onHoverCitation={(documentIds: SourceDocumentId[]) => {
                                                    setHoverCitations(documentIds);
                                                }}
                                                onHoverOffCitation={() => {
                                                    setHoverCitations([]);
                                                }}
                                                hoveredDocument={hoverDocument}
                                                onHoverDocument={(d: SourceDocumentId) => {
                                                    if (hoverEffects) {
                                                        setHoverDocument(d);
                                                    }
                                                }}
                                                onFollowUpQuestionClick={(q) => {
                                                    conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                                    handleAsk({ message: q, mode: settings.assistant.mode })
                                                }}
                                                showFollowUpQuestions={idx === conversation.length - 1}
                                            />
                                            {idx !== conversation.length - 1 && (
                                                <div className="mt-6 mb-8">
                                                    <Divider />
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                return <></>
                            })}

                        {queryState.error && (
                            <ErrorMessage message={`We failed to stream the response: ${queryState.error}. Try asking again`} />
                        )}

                        {askExpired && (
                            <ErrorMessage message="missing response. If you refreshed the page, wait a minute and refresh. Otherwise, continue to ask further questions." />
                        )}
                    </div>
                    <div className={`h-[145px] w-full`} ref={conversationBottomRef}></div>
                    <div style={{ height: `${window.innerHeight * 0.4}px` }} className="bg-transparent"></div>

                    {hasRendered && (
                        <>
                            <div className={`h-[1px] w-full bg-transparent`} ref={conversationBottomMarginRef}></div>
                        </>
                    )}

                    {!isLegacyDesign && (
                        <div className={chatboxContainerStyle}>
                            <ChatBox
                                expertModeAvailable={expertModeAvailable}
                                initialMessage=""
                                handleSubmit={(v) => {
                                    conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                    setTimeout(() => {
                                        handleAsk(v)
                                    }, 100)
                                }}
                                status={streamStatus}
                                isConversation={true}
                                showBorder={!isChatBoxCoveringText}
                            />
                        </div>
                    )}

                    {isLegacyDesign && (
                        <div className="chat-asktextbox-container">
                            <AskTextbox
                                placeholder="Ask Desia"
                                canSubmit={!isBusy}
                                handleAsk={(q: string) => {
                                    handleAsk({ message: q, mode: 'simple' });
                                }}
                                status={streamStatus}
                            />
                        </div>
                    )}
                </div>

                <div className="w-[230px] h-[1px]">

                </div>
            </div>
            <WarnOnNavigate
                open={blocker.state === 'blocked'}
                onConfirm={() => {
                    setShouldResetBlocker(false)
                    blocker?.proceed?.()
                }}
                onCancel={() => {
                    setTimeout(() => {
                        if (shouldResetBlocker) {
                            blocker?.reset?.()
                        }

                        setShouldResetBlocker(true)
                    }, 1)
                }}
            />
        </>
    )
}
