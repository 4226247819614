import { ChatSummary } from "@/types/types";
import { useNavigate } from "react-router-dom";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { useState } from "react";

export function Thread({ thread }: { thread: ChatSummary }) {
    const [openDialog, setOpenDialog] = useState(false)

    const navigate = useNavigate()

    const truncatedQuery = thread.query.length > 20 ? `${thread.query.slice(0, 50)}...` : thread.query

    const handleNavigate = () => {
        navigate(`/assistant/conversation/${thread.conversationId}`)
    }

    return (
        <div className="flex gap-2 items-center px-3 py-2 bg-system-secondary border border-system-border-light rounded-lg cursor-pointer">
            <Button variant='tertiary' className="justify-start w-[calc(100%)]" onClick={() => handleNavigate()}>
                <TypographyBody isStrong={true} className="overflow-hidden text-ellipsis">
                    {thread.query}
                </TypographyBody>
            </Button>

            {/* <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center">
                    <Button variant='tertiary'>
                        <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem
                        variant='tertiary-destructive'
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenDialog(true)
                        }}
                    >
                        Delete chat
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu> */}

            <Dialog open={openDialog} onOpenChange={(v) => setOpenDialog(v)}>
                <DialogContent className="max-w-full sm:max-w-[504px]">
                    <DialogHeader>
                        <DialogTitle className="w-full">
                            <TypographyH4 className="line-clamp-2 w-full">
                                {`You are about to delete “${truncatedQuery}” chat`}
                            </TypographyH4>
                        </DialogTitle>
                        <DialogDescription>
                            <TypographyBody>
                                This action cannot be undone.
                            </TypographyBody>
                        </DialogDescription>
                        <DialogFooter className="pt-8">
                            <DialogClose>
                                <Button variant="secondary-destructive" className="w-full">Delete chat</Button>
                            </DialogClose>
                            <DialogClose>
                                <Button className="w-full">
                                    Keep chat
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </div>
    )
}