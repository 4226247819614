import { MessageCircleMore } from "lucide-react";
import { AssistantAskMode, ChatSummary, DESIA_EVENT, ResponseChatList } from "../../types/types";
import Divider from "../ui/divider";
import { TypographyBody, TypographyH2, TypographyH4 } from "../ui/Typography";
import { ChatBox } from "./ChatBox";
import { SuggestionCard } from "./SuggestionCard";
import { useContext } from "react";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "@/contexts/AssistantContext";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { getTimestamp } from "@/utils/utils";
import { formatDate, isToday, isYesterday } from "date-fns";
import { Thread } from "../Threads/Thread";
import { CustomAlert } from "../CustomAlert";
import { Skeleton } from "../ui/skeleton";

interface Props {
    expertModeAvailable: boolean;
    hasSourceSelector: boolean;
    handleSubmit: ({ message, }: { message: string, mode: AssistantAskMode }) => void;
}
export function NewAsk({ expertModeAvailable, handleSubmit }: Props) {
    const { store, dispatch } = useContext(AssistantStoreContext);
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    const groupThreadsByDate = (threads: ChatSummary[]): { [date: string]: ChatSummary[] } => {
        return threads.reduce((acc: { [date: string]: ChatSummary[] }, item) => {
            const date = new Date(item.updated_at)

            const dateString = isToday(date) ? 'Today' : isYesterday(date) ? 'Yesterday' : formatDate(date, 'd MMMM' + (date.getFullYear() == new Date().getFullYear() ? '' : ' yyyy'))
            if (!acc[dateString]) {
                acc[dateString] = []
            }
            acc[dateString].push(item)
            return acc
        }, {})
    }

    const groupedThreads = groupThreadsByDate(store.list)

    return (
        <div className="flex justify-center align-center h-full mt-10">
            <div className="flex flex-col gap-16 justify-center align-center w-full max-w-[710px]">
                <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-center mx-auto">
                    <TypographyH2>Ask Desia</TypographyH2>
                    <div className="h-8 w-[1px] bg-system-body hidden sm:!flex"></div>
                    <TypographyBody className="text-system-body text-center sm:text-left">
                        Level up your investment research.
                        <br />
                        Minutes, not hours.
                    </TypographyBody>
                </div>

                <div className="flex flex-col gap-8 justify-center">
                    <ChatBox
                        expertModeAvailable={expertModeAvailable}
                        handleSubmit={handleSubmit}
                        additionalControls={true}
                        status="Ready"
                        isConversation={false}
                        showBorder={false}
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
                        <SuggestionCard
                            text="What recent mergers or acquisitions have occurred in the telecom sector?"
                            highlights={['recent mergers', 'acquisitions']}
                            bgColor="#FFE3E3"
                            textColor='#7633AA'
                            handleSubmit={handleSubmit}
                        />
                        <SuggestionCard
                            text="What are the key factors driving NVIDIA's revenue growth?"
                            highlights={["NVIDIA's revenue growth"]}
                            bgColor="#DAFFE0"
                            textColor='#025477'
                            handleSubmit={handleSubmit}
                        />
                        <SuggestionCard
                            text="Determine the staffing and organisational changes needed post-acquisition."
                            highlights={['staffing and organisational changes']}
                            bgColor="#CEF4FD"
                            textColor='#2D4974'
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-[0px] sm:mt-[56px]">
                    <div className="flex gap-2 px-3 items-center">
                        <MessageCircleMore className="h-6 w-6 shrink-0 stroke-[1.5px]" />

                        <TypographyH4>
                            Past chats
                        </TypographyH4>
                    </div>

                    <Divider />

                    <div className="flex flex-col gap-8">
                        {error && (
                            <CustomAlert
                                variant='error'
                                description="We could not load the chats at this time. Please try again later."
                            />
                        )}

                        {!loading && Object.keys(groupedThreads).map((dateString) => {
                            const threads = groupedThreads[dateString] || []
                            return (
                                <div className="flex flex-col gap-4 pb-2">
                                    <TypographyBody isStrong={true} className="px-3 pt-3">
                                        {dateString}
                                    </TypographyBody>

                                    <div className={`grid grid-cols-1 ${threads.length === 1 ? '' : 'sm:!grid-cols-2'}  gap-4`}>
                                        {threads.map((thread) => {
                                            return (
                                                <Thread thread={thread} />
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                        {loading && (
                            <div className="flex flex-col gap-4 pb-2">
                                <div className={`grid grid-cols-1 sm:grid-cols-2  gap-4`}>
                                    <Skeleton className="w-full h-10" />
                                    <Skeleton className="w-full h-10" />
                                    <Skeleton className="w-full h-10" />
                                    <Skeleton className="w-full h-10" />
                                    <Skeleton className="w-full h-10" />
                                    <Skeleton className="w-full h-10" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
