import "./Chat.css";
import Markdown from 'markdown-to-jsx';

import { handleOpenLink, normaliseDocumentId, checkUserFlag, notEmpty } from "../utils/utils";
import { embedCitations } from "../utils/embedCitations";
import { SourceDocumentId } from "../contexts/AssistantContext";
import { Citation } from "../types/types";
import wait from "wait";
import { UserMessage, SourceDocument, SystemMessage } from "../types/types";
import { filterDocumentsByCited, getGlobalUniqueDocuments } from "../utils/components";
import { PreviewSources } from "./Assistant/PreviewSources";
import { FinalAnswer } from "./Assistant/FinalAnswer";
import { Question } from "./Assistant/Question";
import { PlannerSteps } from "./PlannerSteps";
import { ReactNode } from "react";
import { TypographyBody, TypographyLabel } from "./ui/Typography";
import ArrowRight from "@/assets/ArrowRight";
import { Button } from "./ui/button";
import { Sparkles } from "lucide-react";

export function UserChatMessage(props: { message: UserMessage, compact?: boolean, component?: ReactNode }) {
    const isLegacyDesign = checkUserFlag("ui: legacy design system");

    if (isLegacyDesign) return (
        <div className="chat-message">
            <div className="user-message-text">
                {props.message.query}
            </div>
        </div>
    )
    return (
        <div className="max-w-[710px] flex">
            <Question message={props.message.query} compact={props.compact} component={props.component} />
        </div>
    )
}

function getFilteredDocuments(allDocuments: SourceDocument[], isFiltering: boolean, filterCriteria: SourceDocumentId[]) {
    if (!isFiltering) {
        return getGlobalUniqueDocuments(allDocuments);
    }
    const filteredDocuments = allDocuments.filter(d => filterCriteria.includes(d.document_id));
    return getGlobalUniqueDocuments(filteredDocuments);
}

function Document(props: { document: SourceDocument, isFiltering: boolean, isHovered: boolean, onHoverDocument: (d: SourceDocumentId) => void }) {
    return (
        <div
            className={`source-document ${props.isFiltering ? "source-document-filtered" : ""} ${props.isHovered ? "source-document-hovered" : ""}`}
            onClick={() => {
                const id = props.document.document_id;
                const title = props.document.title;
                const url = props.document.url;
                handleOpenLink({
                    id,
                    url,
                    title,
                    documentSource: props.document.doc_metadata?.document_source,
                    documentLink: props.document.doc_metadata?.internal_link,
                    window,
                });
            }}
            onMouseEnter={() => {
                props.onHoverDocument(props.document.document_id);
            }}
            onMouseLeave={() => {
                props.onHoverDocument("");
            }}
        >
            <div className="source-document-title">{props.document.title || props.document.url}</div>
        </div>
    )
}

export type SelectedCitation = {
    messageId: string; // for now hack on frontend by joining conversationid with timestamp
    documentIds: string[],
}

export function getMessageId(message: SystemMessage, messageNumber?: number) {
    const prefix = message.conversationId || message.requestId;
    const suffix = typeof messageNumber === 'number' ? messageNumber : message.data.isFinished ? message.timestamp : "latest";
    return `${prefix}_${suffix}`;
}

function orderFileFirst(documentIds: string[]) {
    const ordered = documentIds.map(id => {
        return {
            id,
            nId: normaliseDocumentId(id)
        }
    }).sort((a, b) => {
        // internal documents ordered first
        // followed by web links when citation
        // references both sources
        if (a.nId.isFile && b.nId.isWeb) return -1;
        if (a.nId.isWeb && b.nId.isFile) return 1;
        return 0;
    }).map(dd => dd.id);
    return ordered;
}

export function orderDocumentsByCitation(documents: SourceDocument[], citations: Citation[]) {
    try {
        const documentIds = documents.map(d => d.document_id);
        const dIDs = documents.map(d => normaliseDocumentId(d.document_id).id);

        const rankedCitationIds = citations
            .sort((a, b) => a.start - b.start)
            .map(c => orderFileFirst(c.document_ids))
            .flat()
            .map(d => normaliseDocumentId(d).id)
            .filter((id) => dIDs.includes(id))
            .reduce((acc, cur) => {
                if (acc.includes(cur)) {
                    return acc;
                }
                return [...acc, cur];
            }, [] as string[]);

        const rankedDocuments = rankedCitationIds
            .map(nId => {
                const cId = rankedCitationIds.indexOf(nId);
                const rank = cId === -1 ? Infinity : cId;
                return {
                    rank,
                    nId,
                }
            })
            .sort((a, b) => {
                if (!isFinite(a.rank) && !isFinite(b.rank)) {
                    if (!a.nId.includes("web") && b.nId.includes("web")) return 1;
                    if (a.nId.includes("web") && !b.nId.includes("web")) return -1;
                    return 0;
                }
                return a.rank - b.rank;
            })
            .map(o => {
                const d = documents.find(dd => normaliseDocumentId(dd.document_id).id === o.nId);
                return d;
            })
            .filter(Boolean);

        const rankedDocumentIds = rankedDocuments.map(d => d?.document_id);
        // the remaining documents are unordered
        // this occurs when citations are streamed back sequentially
        // still, apply basic sort rule to show internal files first
        const unrankedDocuments = documentIds
            .filter(id => !rankedDocumentIds.includes(id)).sort((a, b) => a.localeCompare(b))
            .map(id => documents.find(d => d.document_id === id))
            .filter(Boolean);

        const orderedDocuments = [...rankedDocuments, ...unrankedDocuments].filter(notEmpty);
        return orderedDocuments;
    } catch (e) {
        console.error(e);
        return documents;
    }
}

export function SystemChatMessage({
    onSelectCitation,
    onShowAllCitations,
    onHoverCitation,
    onHoverOffCitation,
    onHoverDocument,
    onFollowUpQuestionClick,
    messageId,
    message,
    selectedCitations,
    hoveredCitations,
    hoveredDocument,
    compact,
    showFollowUpQuestions
}: {
    onSelectCitation: (messageId: string, s: SourceDocumentId[]) => void;
    onShowAllCitations: (messageId: string) => void;
    onHoverCitation: (d: SourceDocumentId[]) => void;
    onHoverOffCitation: () => void;
    onHoverDocument: (d: SourceDocumentId) => void;
    onFollowUpQuestionClick: (question: string) => void
    messageId: string,
    message: SystemMessage,
    selectedCitations: SourceDocumentId[],
    hoveredCitations: SourceDocumentId[];
    hoveredDocument: SourceDocumentId;
    compact?: boolean
    showFollowUpQuestions?: boolean
}) {
    const isFinished = message.data.isFinished;
    const loadingDocuments = !message.data.documents;
    const loadingAnswer = !message?.data?.text;
    const embeddedCitations = embedCitations({
        text: message.data?.text || "",
        citations: message.data.citations || [],
        highlightCitation: hoveredDocument,
    });
    const hoverEffects = checkUserFlag('assistant: highlight citations');

    function Cite({ id, children, documentIds, highlight }: { id: string, children: string, documentIds: string, highlight: boolean }) {
        try {
            const parsedDocumentIds = JSON.parse(documentIds || "[]");
            const classes = highlight ? "cite-highlight" : "cite-text";
            return (
                <span
                    className={classes}
                    onClick={() => {
                        onSelectCitation(messageId, parsedDocumentIds);
                    }}
                    onMouseEnter={async () => {
                        if (hoverEffects) {
                            // fixme: temp hack to allow click events to continue functioning
                            await wait(100);
                            onHoverCitation(parsedDocumentIds);
                        }
                    }}
                    onMouseLeave={() => {
                        if (hoverEffects) {
                            onHoverOffCitation();
                        }
                    }}
                    onMouseOut={() => {
                        if (hoverEffects) {
                            onHoverOffCitation();
                        }
                    }}
                >{children}</span>
            )
        } catch (e) {
            console.error(`[desia-web-app] error rendering Cite with props: ${JSON.stringify({ id, documentIds })}`);
            return children;
        }
    }


    const isFiltering = selectedCitations.length > 0;
    const allDocuments = isFinished ? filterDocumentsByCited(message.data.documents || [], message.data.citations || []) : (message.data.documents || []);
    const filteredDocuments = getFilteredDocuments(allDocuments, isFiltering, selectedCitations);
    const sortedDocuments = orderDocumentsByCitation(filteredDocuments, message.data.citations || []);
    const previewSourcesDocuments = (message.data.text ? sortedDocuments : []).filter(d => !d.document_id.includes("python"));

    const isLegacyDesign = checkUserFlag("ui: legacy design system");

    const plannerSteps = (message.data.plan?.steps || []).map(s => ({
        description: s.description,
        tool: s.next_tools,
        answer: s.answer,
        doc_ref: s.doc_ref
    }))
    const plannerDocuments = message.data.planDocuments || null;
    const showPlanner = !!message.data.plan; // fixme
    const followUpQuestions = (message.data.followUpQuestions || [])

    if (isLegacyDesign) {
        return (
            <div className={`chat-message ${isFinished ? "system-message-loaded" : ""}`}>
                <div className="system-message-content">
                    {(
                        <div>
                            {(loadingDocuments && !isFinished) ? <LoadingDocuments /> : (
                                <>
                                    <div className={`source-documents fade-in-slow`}>
                                        {sortedDocuments.map(d => {
                                            const isHovered = (hoveredCitations || [])
                                                .map(id => normaliseDocumentId(id).id)
                                                .includes(normaliseDocumentId(d.document_id).id);
                                            return (
                                                <Document
                                                    key={d.document_id}
                                                    document={d}
                                                    isFiltering={isFiltering}
                                                    isHovered={isHovered}
                                                    onHoverDocument={onHoverDocument}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="show-all-sources">
                                        {isFiltering && <button onClick={() => {
                                            onShowAllCitations(messageId);
                                        }}>Show all sources</button>}
                                    </div>
                                </>
                            )}

                            {(loadingAnswer && !isFinished) ? <LoadingAnswer /> : (
                                <div className="chat-answer"
                                    onMouseLeave={() => {
                                        onHoverOffCitation();
                                    }}
                                >
                                    <Markdown className={"fade-in-slow"} options={{
                                        overrides: {
                                            Cite: {
                                                component: Cite
                                            },
                                        },
                                        wrapper: 'span',
                                        forceBlock: true,
                                    }}
                                    >
                                        {embeddedCitations}
                                    </Markdown>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const style = `flex justify-center items-center max-w-[710px] ${compact ? 'font-label' : 'font-body'}`

    return (
        <div className={style}>
            <div className="w-full">
                {showPlanner && (
                    <div className="mt-4 flex-1 w-full">
                        <PlannerSteps plannerSteps={plannerSteps} plannerDocuments={plannerDocuments} />
                    </div>
                )}
                <div className="mt-4 w-full">
                    <PreviewSources documents={previewSourcesDocuments} compact={compact} maxIcons={3} />
                </div>
                <div className="mt-6 mx-auto">
                    <div className="mx-6">
                        <FinalAnswer
                            isLoading={loadingAnswer && isFinished !== true}
                            isComplete={!!isFinished}
                            text={message.data?.text || ""}
                            citations={message.data.citations || []}
                            documents={sortedDocuments}
                            compact={compact}
                        />
                    </div>
                    {followUpQuestions.length > 0 && showFollowUpQuestions && (
                        <div className={`flex flex-col gap-6 ${compact ? 'mt-8' : 'mt-12'} mx-6`}>
                            <div className="flex gap-2 items-center">
                                <Sparkles className="w-6 h-6 shrink-0 stroke-[1.5px] stroke-system-body" />

                                <TypographyLabel className="text-system-body">
                                    Suggestions
                                </TypographyLabel>
                            </div>
                            <div className="flex flex-col gap-4 pb-[60px]">
                                {[...followUpQuestions].splice(0, 3).map((question, index) => (
                                    <>
                                        {index != followUpQuestions.length && index !== 0 && (
                                            <div className="w-full h-[1px] bg-system-border-light"></div>
                                        )}

                                        <Button variant='tertiary' size='fit' className="flex gap-2 cursor-pointer w-full" onClick={() => onFollowUpQuestionClick(question)} disabled={!isFinished}>
                                            <TypographyBody isStrong={true} className="text-system-body whitespace-pre-wrap text-left">
                                                {question}
                                            </TypographyBody>

                                            <ArrowRight className="w-6 h-6 ml-auto shrink-0" />
                                        </Button>
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>)
}

function LoadingDocuments() {
    return (
        <div className="source-documents">
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
            <div className="skeleton source-document" />
        </div>
    )
}
function LoadingAnswer() {
    return (
        <div className="placeholder-lines">
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
            <div className="skeleton placeholder-line" />
        </div>
    )
}
export function SystemChatMessageSkeleton() {
    return (
        <div className="system-chat-skeleton">
            <div className="system-message-content">
                <LoadingDocuments />
                <LoadingAnswer />
            </div>
        </div>
    )
}

export function Chat() {
    return (
        <div className="chat-component-container">
            Deprecated: use /Assistant components instead
        </div>
    )
}
