import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useNavigate } from "react-router";
import { captialise, checkDesiaUser, handleLogout, notEmpty } from "../../utils/utils";
import { useContext, useState } from "react";
import { UserContext } from "@/contexts/UserContext";
import { TypographyBody } from "../ui/Typography";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { AssistantStoreContext } from "@/contexts/AssistantContext";
import { WarnOnNavigate } from "../WarnOnNavigate";

export function UserMenu({ showMenu }: { showMenu: boolean }) {
  const [showWarningDialog, setShowWarningDialog] = useState(false)

  const navigate = useNavigate();
  const user = useContext(UserContext);
  const ff = useContext(FeatureFlagContext);
  const { store } = useContext(AssistantStoreContext);
  const isDesiaUser = checkDesiaUser(user.user);

  const activeConversationsId: string[] = store.liveConversations
    .filter(c => {
      const lastMessage = c.conversation[c.conversation.length - 1];
      if (lastMessage.role === "system") {
        return lastMessage.data.isFinished !== true;
      }
      return false;
    })
    .map(c => {
      return [c.conversationId, ...(c.requestIds || [])];
    })
    .flat()
    .filter(notEmpty)

  const pathname = location.pathname;
  const locationSearch = location.search;
  const isCurrentlyViewingConversation = (pathname.includes("/assistant/ask/new/") || pathname.includes("/assistant/conversation/"));
  const conversationIsActive = activeConversationsId.some(id => pathname.includes(id) || locationSearch.includes(id));
  const showWarning = isCurrentlyViewingConversation && conversationIsActive

  const Profile = () => (
    <span
      className="flex items-center gap-2 rounded-lg transition-all hover:text-primary focus:outline-none"
    >
      <img src={user?.user?.picture} className="h-8 w-8 rounded-full" />
      <TypographyBody isStrong={true} className="truncate">{captialise(user.user?.nickname || '')}</TypographyBody>
    </span>
  )

  const handleLogoutClick = () => {
    if (showWarning) {
      setShowWarningDialog(true)
    } else {
      handleLogout()
    }
  }

  if (!showMenu) return Profile()

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {Profile()}
        </DropdownMenuTrigger>

        <DropdownMenuContent align="center" className="w-[160px]">
          <DropdownMenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >Profile</DropdownMenuItem>
          {ff.checkFlag('integration: manage') && (
            <>
              <DropdownMenuItem
                onClick={() => {
                  navigate("/integrations");
                }}
              >Integrations
              </DropdownMenuItem>
            </>
          )}
          {isDesiaUser && (
            <DropdownMenuItem
              onClick={() => {
                navigate("/settings");
              }}
            >Settings
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              handleLogoutClick()
            }}
          >Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <WarnOnNavigate
        open={showWarningDialog}
        onConfirm={() => {
          setShowWarningDialog(false)
          handleLogout()
        }}
        onCancel={() => {
          setShowWarningDialog(false)
        }}
      />
    </>

  )
}