import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../ui/dialog";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import { FileSearch } from "lucide-react";

export const NotAvailableDialog = ({ open, setOpen }: { open: boolean, setOpen: (v: boolean) => void }) => {
    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="border-0 shadow-none">
                <DialogTitle className="relative h-8 mb-2">
                    <div className="absolute left-[calc(50%-16px)]">
                        <FileSearch className="w-8 h-8 shrink-0 stroke-[1.5px]" />
                    </div>
                </DialogTitle>
                <DialogDescription className="text-center">
                    <div className="flex flex-col gap-2">
                        <TypographyH4 className="text-system-primary">
                            Document analysis is coming soon
                        </TypographyH4>
                        <TypographyBody className="text-system-body">
                            We are working hard to bring this feature to the platform. We will let you know once ready.
                        </TypographyBody>

                    </div>
                </DialogDescription>
            </DialogContent>
        </Dialog>
    )
}