import { Button } from "../ui/button"
import { Card, CardContent } from "../ui/card"
import { Integration, IntegrationStatus } from "@/types/types"
import { useNavigate } from "react-router-dom"
import { Progress } from "../ui/progress"
import { TypographyBody, TypographyLabel } from "../ui/Typography"
import CheckmarkCircleFill from "@/assets/CheckmarkCircleFill"
import CloseCircleFill from "@/assets/CloseCircleFill"
import { CustomAlert } from "../CustomAlert"
import { getIntegrationName } from "@/utils/utils"
import { getIntegrationIcon } from "@/utils/components"

export interface IntegrationCardProps {
    integration: Integration
    detail: IntegrationStatus | null
}

export const IntegrationCard = ({ integration, detail }: IntegrationCardProps) => {
    const navigate = useNavigate()

    const isProcessing = integration.integration_has_setup && detail?.synchronization_details.files_to_synchronize_count && (detail?.synchronization_details.available_count || 0) + (detail?.synchronization_details.failed_count || 0) !== detail?.synchronization_details.files_to_synchronize_count
    const processingCount = (detail?.synchronization_details.available_count || 0) + (detail?.synchronization_details.failed_count || 0)
    const hasError = integration.integration_has_setup && (detail?.synchronization_details.failed_count || 0) > 0

    return (
        <Card className="min-h-[168px]">
            <CardContent className="flex gap-10 p-6 h-full">
                <div className="flex flex-col gap-0">
                    <div className="flex-grow">
                        <div className="flex flex-col sm:flex-row gap-8 overflow-hidden">
                            {getIntegrationIcon(integration.integration_code_name)}

                            {hasError && !isProcessing && (
                                <div className="flex flex-col sm:flex-row gap-6 sm:items-center">
                                    <div className="flex gap-2">
                                        <CheckmarkCircleFill className="w-6 h-6 shrink-0" />
                                        <TypographyBody className="shrink-0 overflow-hidden text-ellipsis">
                                            {`${detail?.synchronization_details.available_count || 0} files processed`}
                                        </TypographyBody>
                                    </div>
                                    <div className="flex gap-2">
                                        <CloseCircleFill className="w-6 h-6 shrink-0" />
                                        <TypographyBody className="shrink-0 overflow-hidden text-ellipsis">
                                            {`${detail?.synchronization_details.failed_count || 0} files failed to process`}
                                        </TypographyBody>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {!isProcessing && !hasError && (
                        <div>
                            {integration.integration_has_setup
                                ?
                                <Button className="mt-auto" onClick={() => navigate(`/integrations/${integration.integration_code_name}`)}>
                                    Manage
                                </Button>
                                :
                                <Button variant='secondary' className="mt-auto" onClick={() => navigate(`/integrations/setup/${integration.integration_code_name}`)}>
                                    Connect
                                </Button>
                            }
                        </div>
                    )}

                    {hasError && !isProcessing && (
                        <div className="mt-4">
                            <CustomAlert
                                variant="error"
                                title={`We could not process some of the ${getIntegrationName(integration.integration_code_name)} files `}
                                description={`${detail?.synchronization_details.available_count || 0}/${detail?.synchronization_details.files_to_synchronize_count || 0} files were processed. Our team has been notified.`}
                            />
                        </div>
                    )}
                </div>

                {isProcessing && (
                    <div className="flex flex-col gap-8 w-full">
                        <div className="flex flex-col gap-3">
                            <Progress value={processingCount / (detail?.synchronization_details.files_to_synchronize_count || 0) * 100} />

                            <TypographyLabel className="text-system-body">
                                {`Uploading and processing ${processingCount} / ${detail?.synchronization_details.files_to_synchronize_count} files`}
                            </TypographyLabel>
                        </div>

                        {/* <div className="mt-auto">
                            <Button variant='secondary'>
                                Cancel and undo changes
                            </Button>
                        </div> */}
                    </div>
                )}

            </CardContent>
        </Card>
    )
}