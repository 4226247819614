import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { SourceConnector } from "../../types/types";
import { Checkbox } from "../ui/checkbox";
import { TypographyBody } from "../ui/Typography";
import DocumentSelectorDialog from "./DocumentSelectorDialog";
import { plural } from "@/utils/utils";

export function SourceSelector({ compact }: { compact?: boolean }) {
    const { settings, updateSettings } = useContext(UserContext);

    const sources = settings.assistant.sources;
    // @ts-expect-error
    const selectedFiles = sources.find((v) => v.id === 'internal-search')?.include || []

    function toggleSource(source: SourceConnector) {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: settings.assistant.sources.find((v) => v.id === source.id) ? settings.assistant.sources.filter(s => s.id !== source.id) : [...settings.assistant.sources, source]
                }
            }
        })
    }

    return (
        <div className={`${compact ? "" : "mt-4"} px-2`}>
            <div className="flex gap-6 items-center">
                <TypographyBody>Sources:</TypographyBody>
                <div className="flex items-center gap-2">
                    <Checkbox id="internal-search-checkbox"
                        checked={!!sources.find((v) => v.id === 'internal-search')}
                        onCheckedChange={() => {
                            toggleSource({ id: 'internal-search' });
                        }}
                        label={selectedFiles.length ? `${selectedFiles.length} ${plural('file', selectedFiles.length)}` : "Library"}
                    />

                    <DocumentSelectorDialog />
                </div>

                <div className="flex items-center gap-2">
                    <Checkbox id="web-search-checkbox"
                        checked={!!sources.find((v) => v.id === 'web-search')}
                        onCheckedChange={() => {
                            toggleSource({ id: 'web-search' });
                        }}
                        label="Web"
                    />
                </div>
            </div>
        </div>
    )
}
